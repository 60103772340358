<template>
  <div 
    v-if="isLoaded()"
    class="
      duck-form 
      add--project--form
    "
  >
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >

          <SmallTitle 
            title="ที่ตั้งโครงการ" 
            class="bottom-line"
          />
          <AddressInput 
            ref="addressInput" 
            :item="formAddress"
          />


          <SmallTitle 
            title="ความพร้อมของงาน" 
            class="mt-20 bottom-line"
          />
          <ReadinessBlock
            ref="readinessBlock" 
            :project="formReadiness"
          />
          
          <ProjectSubmitBlock text="บันทึกข้อมูลโครงการ" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
const _ = require('lodash/core');
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Load components
import AddressInput from '@/components/common/form/input/AddressInput.vue';
import ReadinessBlock from '@/components/project/form/input/ReadinessBlock.vue';
import ProjectSubmitBlock from '@/components/project/form/button/ProjectSubmitBlock.vue';
import SmallTitle from '@/components/common/SmallTitle.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  data() {
    return {
      projectId: '',
      project: {},
      formAddress: {},
      formReadiness: {},
    }
  },
  components: {
    AddressInput,
    ReadinessBlock,
    ProjectSubmitBlock,
    SmallTitle,
  },
  created() {
    this.projectId = this.$route.params.id;

    // set page title
    this.$store.commit('setPageTitle', 'Edit Project');
    this.$store.commit('setHeadTitle', 'Edit Project : Location');

    // get project data
    this.setForm();
  },

  /**
   * Methods
   */
  methods: {/**
     * Detect page loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Get project data
     */
    setForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.projectId)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้งหรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;

            this.formReadiness = {
              area:         this.project.area,
              road_area:    this.project.road_area,
              current_step: this.project.current_step,
              facility_plumbing:    parseInt(this.project.facility_plumbing),
              facility_electricity: parseInt(this.project.facility_electricity),
              facility_internet:    parseInt(this.project.facility_internet),
              has_boq:  this.project.has_boq,
              has_plan: this.project.has_plan,
              estate:   this.project.estate,
            }
            this.formAddress = {
              address: this.project.address,
              subdistrict: this.project.subdistrict,
              district: this.project.district,
              province: this.project.province,
              zipcode: this.project.zipcode,
            }
            
            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * Save value to database via API
     */
    saveForm() {
    //   // this.$v.$touch()
      this.$refs.addressInput.$v.$touch();
      this.$refs.readinessBlock.$v.$touch();

      // if not validate error
      // update data to database by API
      if (!this.$refs.addressInput.$v.$invalid && !this.$refs.readinessBlock.$v.$invalid) {
        // show main loader
        this.$store.commit('setLoaderShow', true);

        let projectId = this.$route.params.id;
        let form = _.extend(
          { project_id: projectId },
          this.$refs.addressInput.$data.form,
          this.$refs.readinessBlock.$data.form
        );
        if(form.facility_plumbing === false) form.facility_electricity = 0;
        if(form.facility_electricity === false) form.facility_electricity = 0;
        if(form.facility_internet === false) form.facility_electricity = 0;

        apiRequest
          .patch(
            '/project/',
            form
          )
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/view/' + projectId);
            } else {
              console.log(res.data);
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },
  }
}
</script>